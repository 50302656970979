<template>
  <div>
    <ConfigurationHeaderContainer title="Preferences for Project X"></ConfigurationHeaderContainer>
    <div class="form-entry-container">
       <div class="form-entry-label">System Filters</div>
         <div class="form-entry-input">
            <div class="stages">
            <div class="stages-item">
            <div class="forms">
              <div>
                <kendo-datasource
                  :ref="'filterDataSource'"
                   :data="filterDataSource.data">
                </kendo-datasource>
                <kendo-listbox
                  :id="'filterListBox'"
                  :data-source-ref="'filterDataSource'"
                  :data-value-field="'FilterIdentity'"
                  :data-text-field="'FilterName'"
                  :draggable="true"
                  :drop-sources="['selected']"
                  :toolbar-tools="['moveUp', 'moveDown']"
                  style="height:120px; padding-right: 15px;">
                </kendo-listbox>
                <div>
                  <a href="#">Add</a>
                  <a href="#">Edit</a>
                  <a href="#">Delete</a>
                </div>
              </div>
              <div>
              <div>Manage Filters</div>

                <kendo-grid
                  :id="'manageFilterGrid'"
                  :ref="'manageFilterGrid'"
                  :data-source-ref="'filterDataSource'"
                  style="width: calc(100% - 320px);">
                </kendo-grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FormEntryContainer title="Default Filter" type="dropdown" :datasource="this.filterDataSource" textfield="FilterName"></FormEntryContainer>
    <FormEntryContainer title="Items per Page" type="text" value="10"></FormEntryContainer>
    <div class="form-entry-container">
      <div class="form-entry-label">Attach/Detach Cards</div>
      <div class="form-entry-input">
      </div>
    </div>
  </div>
</template>

<script>
import ConfigurationHeaderContainer from '@/containers/ConfigurationHeaderContainer'
import FormEntryContainer from '@/containers/FormEntryContainer'
import FilterDataSource from '@/assets/data/Filter.json'

export default {
  name: 'stages',
  components: {
    ConfigurationHeaderContainer,
    FormEntryContainer
  },
  data () {
    return {
      filterDataSource: FilterDataSource
    }
  }
}
</script>
